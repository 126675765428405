import { BookingRoutes } from '@/Booking/booking.const';
import { createToastInterface } from 'vue-toastification';
import toastOptions from '@/config/plugins/vueToastificationOptions';
const toast = createToastInterface(toastOptions);
export const useSharePaymentLink = (booking, root, prefix) => {
    const setLink = async () => {
        if (process.browser) {
            const link = `${window.location.origin}/${prefix}${BookingRoutes.BOOKING}${BookingRoutes.PAYMENT}/${booking.booking_hash}`;
            try {
                root.$copyText(link);
                toast.success(root.$i18n.t('booking.details.pay.share_to_pay.success'));
            }
            catch (e) {
                toast.error(root.$i18n.t('booking.details.pay.share_to_pay.error'));
            }
        }
    };
    return {
        setLink
    };
};
